<template>
  <el-container>
    <el-header>
      <span style="float: left;cursor: pointer">
        陆遥LOGO
      </span>
      <span style="float: right; font-size: 12px;">
        <el-dropdown>
           <i class="el-icon-setting" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown" placement="bottom">
          <el-dropdown-item @click.native="changePassword()">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="outlogin()">登出</el-dropdown-item>
        </el-dropdown-menu>
        </el-dropdown>
      <span>{{ showusername() }}</span>
      </span>
    </el-header>
    <el-container style="height: auto;">
      <el-aside width="200px" >
        <Menu></Menu>
      </el-aside>
      <el-main style="background-color: #f0f2f5;">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>
<script src="./login/jquery-3.5.1.min.js"></script>
<script>
import Menu from "./Menu.vue";
import Cookies from "js-cookie";

export default {
  data() {
    return {};
  },
  components: {
    Menu,
  },
  methods: {

    outlogin() {
      this.$api.get(
          "/user/logout",
          null,
          null,
          (successRes) => {
            localStorage.clear();
            window.location.href = "/management";

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    showusername() {
      return Cookies.get("username");

    },
    changePassword() {
      this.$router.push({path: "/management/main/changePassword"});
    },
  },
  // mounted() {
  //   this.getCookie("username");
  // },
};
</script>
<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  height: 100px;
}

.el-aside {
  color: #ffffff;
}
</style>