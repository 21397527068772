import axios from "axios";
import {Message} from 'element-ui';
import Cookies from 'js-cookie';

// const baseURL = "http://api.zzmeetluyao.com/mock/13/luyao";
const baseURL = "https://sites.zzmeetluyao.com/api/luyao"
// const baseURL = "http://127.0.0.1:8080/api/luyao/"
// const baseURL = "http://192.168.31.168:9000/luyao"

axios.defaults.headers.post['content-Type'] = 'application/json;charset=UTF-8';
function toType (obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
/** 参数过滤函数*/
function filterNull (o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}
function setHeaders(headers){
  var token = Cookies.get("luyao_token");
  if(token){
    axios.defaults.headers.Authorization = token;
  }
}
function api(method,url,headers,params,success,failure){
    if(headers){
      setHeaders(headers); 
    }
    if (params) {
      params = filterNull(params)
    }
    axios({
        method:method,
        url:url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        baseURL:baseURL
    }).then(function (res){
        if(res.status == 200){
          success(res.data);
        }else{
          failure(res.data)
        }
    }).catch(function(error){
      Message({
          message: '当前服务不可用！',
          type: 'error'
        });
    })
}
export {baseURL}
export default {
   
    get: function (url,headers, params, success, failure) {
        return api('GET', url,headers, params, success, failure)
      },
      post: function (url,headers, params, success, failure) {
        return api('POST', url,headers, params, success, failure)
      },
      put: function (url,headers, params, success, failure) {
        return api('PUT', url,headers, params, success, failure)
      },
      delete: function (url,headers, params, success, failure) {
        return api('DELETE', url,headers, params, success, failure)
      },
}