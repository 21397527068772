import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from "element-ui";
import "./assets/global.scss";
import ajax from '@/utils/ajax.js';
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

router.beforeEach((to, from, next) => {
  let isLogin = window.localStorage.getItem('token')
   if (isLogin) {
     next()
   } else {
     if (to.path === '/management') {
      next()
    } else {
      alert("请先登录")
      next('/management')
    }
  }
})

// Vue.prototype.$ = $;
// Vue.prototype.$axios = axios
Vue.prototype.$api = ajax
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
