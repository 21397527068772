import Vue from 'vue';
import VueRouter from 'vue-router';
import layout from '../components/layout/Main.vue';

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes:[
        
        {
            path:"/",
            redirect:"/management",
            name:"default"
        },
        {
            path:"/management",
            component:{
                render:c=>c("router-view")
            },
            children:[
                {
                    path:"/",
                    name:"management.login",
                    component: () => import('@/login/Index.vue'),
                },
                {
                    path:"main",
                    name:"management.main",
                    component:layout,
                    children:[
                        {
                            path:"/",
                            name:"main",
                            component: () => import('@/components/Dashboard.vue')
                        },
                        {
                            path:"changePassword",
                            name:"changePassword",
                            component: () => import('@/components/layout/changePassword.vue')
                        },
                        // {
                        //     path: '*',
                        //     name:"error",
                        //     component: () => import('@/login/error.vue'),
                            
                        //   },
                        //   {
                        //     path: '/error',
                        //     name:"error500",
                        //     component: () => import('@/login/error500.vue'),
                            
                        //   }
                    ]
                },
                {
                    path:"article",
                    name:"management.article",
                    component:layout,
                    children:[
                        {
                            path:"list",
                            name:"articleList",
                            component: () => import('@/components/article/ArticleList.vue')
                        },
                        {
                            path:"add",
                            name:"articleAdd",
                            component: () => import('@/components/article/ArticleAdd.vue')
                        },
                        {
                            path:"articleedit",
                            name:"articleedit",
                            component: () => import('@/components/article/Articleedit.vue')
                        },
                        {
                            path:"examineList",
                            name:"examineList",
                            component: () => import('@/components/article/examineList .vue')
                        },
                        {
                            path:"examineedit",
                            name:"examineedit",
                            component: () => import('@/components/article/examineedit.vue')
                        }
                    ]
                },
                {
                    path:"customer",
                    name:"management.customer",
                    component:layout,
                    children:[
                        {
                            path:"customerList",
                            name:"customerList",
                            component: () => import('@/components/customer/customerList.vue')
                        },
                        {
                            path:"customerAdd",
                            name:"customerAdd",
                            component: () => import('@/components/customer/customerAdd.vue')
                        },
                        {
                            path:"customeredit",
                            name:"customeredit",
                            component: () => import('@/components/customer/customeredit.vue')
                        }
                    ]
                },
                {
                    path:"desk",
                    name:"management.desk",
                    component:layout,
                    children:[
                        {
                            path:"deskList",
                            name:"deskList",
                            component: () => import('@/components/desk/deskList.vue')
                        },
                        {
                            path:"deskAdd",
                            name:"deskAdd",
                            component: () => import('@/components/desk/deskAdd.vue')
                        }
                    ]
                },
                {
                    path:"master",
                    name:"management.master",
                    component:layout,
                    children:[
                        {
                            path:"masterList",
                            name:"masterList",
                            component: () => import('@/components/master/masterList.vue')
                        },
                        {
                            path:"masterAdd",
                            name:"masterAdd",
                            component: () => import('@/components/master/masterAdd.vue')
                        },
                        {
                            path:"masteredit",
                            name:"masteredit",
                            component: () => import('@/components/master/masteredit.vue')
                        },
                        {
                            path:"mmasterList",
                            name:"mmasterList",
                            component: () => import('@/components/master/mmasterList .vue')
                        },
                        {
                            path:"mmasteredit",
                            name:"mmasteredit",
                            component: () => import('@/components/master/mmasteredit.vue')
                        },
                        {
                            path:"mmasterAdd",
                            name:"mmasterAdd",
                            component: () => import('@/components/master/mmasterAdd.vue')
                        }
                    ]
                },
                {
                    path:"name",
                    name:"management.name",
                    component:layout,
                    children:[
                        {
                            path:"nameList",
                            name:"nameList",
                            component: () => import('@/components/name/nameList.vue')
                        },
                        {
                            path:"nameAdd",
                            name:"nameAdd",
                            component: () => import('@/components/name/nameAdd.vue')
                        },
                        {
                            path:"nameedit",
                            name:"nameedit",
                            component: () => import('@/components/name/nameedit.vue')
                        },
                    ]
                },
                {
                    path:"keyword",
                    name:"management.keyword",
                    component:layout,
                    children:[
                        {
                            path:"keyword",
                            name:"keyword",
                            component: () => import('@/components/keyword/keywordList .vue')
                        },
                        {
                            path:"update",
                            name:"update",
                            component: () => import('@/components/keyword/update.vue')
                        },
                        {
                            path:"updates",
                            name:"updates",
                            component: () => import('@/components/keyword/updates.vue')
                        },
                        {
                            path:"datashow",
                            name:"datashow",
                            component: () => import('@/components/keyword/datashow.vue')
                        },
                        {
                            path:"feedback",
                            name:"feedback",
                            component: () => import('@/components/keyword/feedback.vue')
                        },
                        {
                            path:"aupdates",
                            name:"aupdates",
                            component: () => import('@/components/keyword/aupdates.vue')
                        },
                        {
                            path:"choose",
                            name:"choose",
                            component: () => import('@/components/keyword/choose.vue')
                        },
                        {
                            path:"types",
                            name:"types",
                            component: () => import('@/components/keyword/type.vue')
                        },
                    ]
                },
                {
                    path:"modular",
                    name:"management.modular",
                    component:layout,
                    children:[
                        {
                            path:"addmodular",
                            name:"addmodular",
                            component: () => import('@/components/modular/addmodular.vue')
                        },
                        {
                            path:"editmodular",
                            name:"editmodular",
                            component: () => import('@/components/modular/editmodular.vue')
                        },
                        {
                            path:"addmodularlist",
                            name:"addmodularlist",
                            component: () => import('@/components/modular/addmodularlist.vue')
                        },
                        {
                            path:"editmodularlist",
                            name:"editmodularlist",
                            component: () => import('@/components/modular/editmodularlist.vue')
                        },

                        {
                            path:"modularlist",
                            name:"modularlist",
                            component: () => import('@/components/modular/modularlist.vue')
                        },
                        
                        {
                            path:"modulartypelist",
                            name:"modulartypelist",
                            component: () => import('@/components/modular/modulartypelist.vue')
                        },
                        {
                            path:"productaudit",
                            name:"productaudit",
                            component: () => import('@/components/modular/productaudit.vue')
                        },
                        {
                            path:"productauditedit",
                            name:"productauditedit",
                            component: () => import('@/components/modular/productauditedit.vue')
                        },

                    ]
                },
            ]
        },
        
    ]
})
export default router;