<template>
  <el-menu router :default-openeds="['1']" default-active="/management/desk/deskList" text-color="#fff" active-text-color="#3b86d2" background-color="#304156">
    <el-menu-item index="/management/desk/deskList" v-show="customshow()" >
        <i class="el-icon-menu"></i>
        <span slot="title">主面板</span>
      </el-menu-item>
    <!-- <el-submenu index="1" v-show="customshow()">
      <template slot="title">
        <router-link to="/management/desk/deskList" tag="span" slot="title">
          <i class="el-icon-message"></i>主面板
        </router-link>
      </template>
    </el-submenu> -->
    <el-submenu index="2" v-show="isshow()">
      <template slot="title">
        <i class="el-icon-s-custom"></i>客户
      </template>
      <el-menu-item index="/management/customer/customerList">客户列表</el-menu-item>
    </el-submenu>
    <el-submenu index="3" v-show="isshow()">
      <template slot="title">
        <i class="el-icon-user-solid"></i>用户
      </template>
      <el-menu-item index="/management/name/nameList">用户列表</el-menu-item>
    </el-submenu>
    <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-s-order"></i>文章
      </template>
      <el-menu-item index="/management/article/list">文章列表</el-menu-item>
      <el-menu-item index="/management/article/examineList" v-show="isshow()">文章审核</el-menu-item>
    </el-submenu>
    <el-submenu index="5" v-show="customshow()">
      <template slot="title">
        <i class="el-icon-s-unfold"></i>类别管理
      </template>
      <el-menu-item index="/management/master/masterList">大类别列表</el-menu-item>
      <el-menu-item index="/management/master/mmasterList">中类别列表</el-menu-item>
    </el-submenu>
    <el-submenu index="6" v-show="bisshow()">
      <template slot="title">
        <i class="el-icon-s-promotion"></i>推广
      </template>
      <!-- <el-menu-item index="/management/keyword/keyword">关键词列表</el-menu-item>
      <el-menu-item index="/management/keyword/update">关键词上传</el-menu-item> -->
     <el-menu-item index="/management/keyword/datashow">爱采购展示</el-menu-item>
     <el-menu-item index="/management/keyword/feedback">数据反馈</el-menu-item>
      <el-menu-item index="/management/keyword/aupdates">爱采购上传</el-menu-item>

    </el-submenu>
    <el-submenu index="7" v-show="bisshow()">
      <template slot="title">
        <i class="el-icon-s-marketing"></i>运营
      </template>
      <el-menu-item index="/management/keyword/choose">选词助手</el-menu-item>
      <el-menu-item index="/management/keyword/types">品类罗盘</el-menu-item>
      <el-menu-item index="/management/keyword/updates">上传</el-menu-item>
    </el-submenu>
    <el-submenu index="8">
      <template slot="title">
        <i class="el-icon-s-cooperation"></i>产品
      </template>
      <el-menu-item index="/management/modular/modularlist">产品列表</el-menu-item>
      <el-menu-item index="/management/modular/modulartypelist">产品类型</el-menu-item>
      <el-menu-item index="/management/modular/productaudit" v-show="isshow()">产品审核</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      good: true,
      bad: true,
      custome: true,
    };
  },
  methods: {
    
    getUserInfoByUserName() {
       const username = Cookies.get("username");
      this.$api.get(
        "/user/getUserInfoByUserName",
        null,
        {
          username: username,
        },
        (successRes) => {
          if (successRes.data.userType != -99) {
            this.good = false;
          } else {
            this.good = true;
          }
          if (
            successRes.data.userType == 1 ||
            successRes.data.userType == -99
          ) {
            this.bad = true;
          } else {
            this.bad = false;
          }
          if (successRes.data.customerId != 0) {
            this.custome = false;
          } else {
            this.custome = true;
          }
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
    isshow() {
      console.log(this.good);
      return this.good;
    },
    bisshow() {
      console.log(this.good);
      return this.bad;
    },
    customshow() {
      console.log(this.good);
      return this.custome;
    },
  },
  mounted() {
    this. getUserInfoByUserName();
  },
};
</script>